import { cn } from "../utils/cn";
import BcLogoSmall from "./BCLogoSmall";

export const BcSign = ({className}) => {
  return (
    <div className={cn("absolute right-16 bottom-24 bg-ui-dark-gray p-1 rounded-xl flex flex-row items-center pointer-events-none shadow-lg", className)}>
      <BcLogoSmall />
    </div>
  );
};
