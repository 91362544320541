const Button = ({ label, icon, action, active, isWip }) => {
  return (
    <button
      className={`${
        active ? "bg-ui-red/90 pointer-events-none" : "bg-ui-black"
      } rounded-[4px] p-4 w-full flex flex-row items-center justify-start hover:bg-ui-red/70 gap-x-4 hover:-translate-y-1 hover:shadow-black/30 hover:shadow-lg transition-all duration-200 `}
    >
      {icon} <span>{label}{isWip && <span className="text-[12px] ml-1.5 text-white/80">soon</span>}</span>
    </button>
  );
};
export default Button;
