import { useState, useEffect } from "react";
import PeTableHeader from "../PeTableHeader";
import PeTableRow from "../PeTableRow";
import { sortObjectsArray } from "../../utils/dataCleaning";
import { GenericInput } from "../GenericInput";
import { LuFilter, LuFilterX } from "react-icons/lu";
import { MdClose } from "react-icons/md";

const PeTable = ({ dataset, action }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [showFilters, setShowfilters] = useState(true);
  const [sortBy, setSortBy] = useState("id");
  const [isDescending, setIsDescending] = useState(false);
  const [tokenName, setTokenName] = useState("");
  const [minPe, setMinPe] = useState(null);
  const [minMK, setMinMK] = useState(null);

  const handleSorting = (val) => {
    if (sortBy === val) {
      setIsDescending(!isDescending);
    } else {
      setSortBy(val);
    }
  };
  const resetFilters = () => {
    setTokenName("");
    setMinPe(null);
    setMinMK(null);
  };
  useEffect(() => {
    const filtered = dataset.filter((item) => {
      const matchesTokenName =
        tokenName.length === 0 ||
        item.name.toLowerCase().includes(tokenName.toLowerCase());

      const matchesMinPe = minPe <= 0 || item.peRatio <= minPe;
      const matchesMinMK = minMK <= 0 || item.marketCap >= Number(minMK);

      return matchesTokenName && matchesMinPe && matchesMinMK;
    });
    setFilteredData(filtered);
  }, [tokenName, minPe, minMK]);
  return (
    <div className="w-full mt-10">
      <div className="w-full border border-neutral-300/20  p-2 rounded-sm ">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-x-2">
            <div>Table filters</div>
            <button
              className="p-1 border border-neutral-300/20  rounded-sm hover:bg-neutral-200/10 relative"
              onClick={() => setShowfilters(!showFilters)}
            >
              {!showFilters ? <LuFilter /> : <MdClose />}
              {tokenName.length > 0 || minPe > 0 || minMK > 0 ? (
                <div className="absolute bg-red-500 w-3.5 h-3.5 -top-1.5 -right-1.5 rounded-full text-[10px] font-bold flex items-center justify-center">
                  {(tokenName.length > 0 ? 1 : 0) +
                    (minPe > 0 ? 1 : 0) +
                    (minMK > 0 ? 1 : 0)}
                </div>
              ) : null}
            </button>
          </div>
          {tokenName.length > 0 || minPe > 0 || minMK > 0 ? (
            <div className="flex flex-row items-center gap-x-2">
              <div className="text-xs">Reset filters</div>{" "}
              <button
                className="p-1 border border-neutral-300/20  rounded-sm hover:bg-red-500/80"
                onClick={() => resetFilters()}
              >
                <LuFilterX />
              </button>
            </div>
          ) : null}
        </div>
        <div
          className={`flex flex-col w-full ${
            showFilters ? "h-[185px] md:h-[65px]" : "h-0"
          } overflow-hidden transition-all duration-300`}
        >
          <div className="w-full bg-neutral-300/20 h-[1px] my-2.5"></div>
          <div
            className={`w-full flex flex-col md:grid md:grid-cols-4 gap-x-2 gap-y-2 items-start md:items-center`}
          >
            <div className="text-sm">Filter by:</div>
            <GenericInput
              label={"Token name"}
              action={setTokenName}
              value={tokenName}
              active={tokenName?.length > 0}
            />
            <GenericInput
              label={"PE Value (<= than)"}
              action={setMinPe}
              type="number"
              min="0"
              value={minPe}
              active={minPe > 0}
            />
            <GenericInput
              label={"Market cap (>= than)"}
              action={setMinMK}
              type="number"
              min="0"
              value={minMK}
              active={minMK > 0}
            />
          </div>
        </div>
      </div>

      <div
        className="w-full border border-neutral-300/20  mt-2.5 mb-[40px] rounded-sm max-md:max-w-full max-md:overflow-x-auto bg-contain	bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/black-logo.webp)`,
        }}
      >
        <PeTableHeader clickedSort={handleSorting} selectedId={sortBy} />
        <div className="w-full h-fit max-h-[50vh] overflow-y-auto">
          {sortObjectsArray(filteredData, sortBy, isDescending).map((data, i) => (
            <PeTableRow infos={data} key={data.name + '-'+i} action={action} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default PeTable;
