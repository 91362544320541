"use client";
import axios from "axios";
import { useEffect, useState } from "react";
import PeLinechart from "./PeLinechart";
import Loader from "../../Loader";
import PeSingleProjectInfos from "../../PE/PeSingleProjectInfos";
import { FaChevronLeft } from "react-icons/fa6";
import TwoColsDescription from "../../TwoColsDescription";
import PeRevenues from "./PeRevenues";

function filterDataByDate(dataArray) {
  const targetDate = new Date("2024-09-19T00:00:00Z");
  return dataArray.filter((item) => new Date(item.date) > targetDate);
}
const PeSingleElementCharts = ({ id, goBack, extraInfos }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTexts, setIsLoadingTexts] = useState(false);
  const [data, setData] = useState(null);
  const [holdersRevText, setHolderRevText] = useState(null);
  const [projectRevText, setProjectRevText] = useState(null);
  const [graphInfo, setGraphInfo] = useState([]);

  useEffect(() => {
    setIsLoadingTexts(true);
    axios
      .get(`https://api.llama.fi/summary/fees/${id}?dataType=dailyFees`)
      .then(function (response) {
        // handle success
        if (response?.data?.methodology) {
          if (response?.data?.methodology?.HoldersRevenue) {
            setHolderRevText(response.data.methodology.HoldersRevenue);
          }
          if (response?.data?.methodology?.ProtocolRevenue) {
            setProjectRevText(response.data.methodology.ProtocolRevenue);
          }
        }
        setIsLoadingTexts(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoadingTexts(false);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://api.bc.army/api/pe-data/${id}/`)
      .then(function (response) {
        // handle success
        setGraphInfo(filterDataByDate(response.data.graphInfo));
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="w-full pb-5 max-md:h-full max-md:max-h-screen overflow-y-auto">
      <div
        className="flex flex-row items-center hover:text-ui-red cursor-pointer text-sm"
        onClick={goBack}
      >
        <FaChevronLeft /> Go back
      </div>
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">Loading Data...</div>
          </div>
        </div>
      ) : null}
      {!isLoading && data?.projectInfo ? (
        <div className="w-full pt-5">
          <div className="text-xl mb-1.5 uppercase">
            {data.projectInfo?.name}
          </div>
          {data.projectInfo?.description?.length ? (
            <div className="text-xs mb-2.5 text-white/70">
              {data.projectInfo.description}
            </div>
          ) : null}
          <div className="h-[40vh] w-full relative">
            <img
              src="/black-logo.webp"
              alt="bc logo"
              className="pointer-events-none h-[70%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
            />
            <PeLinechart numbers={graphInfo} />
          </div>
          <div className="flex flex-col md:flex-row w-full mt-5 gap-x-5 md:gap-x-10">
            <div className="w-full md:w-[40%]">
              <PeSingleProjectInfos
                extraInfos={extraInfos}
                infos={data.projectInfo}
                lastPrice={
                  graphInfo[graphInfo.length - 1]?.price
                    ? graphInfo[graphInfo.length - 1]?.price
                    : 0
                }
              />
            </div>
            <div className="w-full md:h-[400px] mt-5 md:mt-0 pb-10 md:pb-0">
              {/* <TokenUnlock info={singlePe.projectInfo.tokenFake} /> */}
              <TwoColsDescription
                textHolders={holdersRevText}
                textProject={projectRevText}
                token={id}
              />
              <PeRevenues numbers={extraInfos} />
            </div>
          </div>
        </div>
      ) :  <div className="relative w-full h-[80vh] bg-ui-black rounded-md z-50 border border-neutral-300/30 flex flex-col items-center justify-center">
      <img
        src="/black-logo.webp"
        alt="bc logo"
        className="pointer-events-none h-[90%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10 "
      />
      <div className="text-2xl">We encountered an error.</div><div className="text-3xl text-red-700"> We'll fix it soon.</div>
    </div>}
    </div>
  );
};
export default PeSingleElementCharts;
