import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";

export const HomeKpi = ({
  value,
  label,
  variation,
  hidePerc,
  isFEG,
  fegToday,
  fegYesterday,
}) => {
  const positiveVariation = variation ? variation > 0 : null;
  return (
    <div className="flex flex-col items-center">
      <div className="uppercase font-semibold text-xs whitespace-nowrap oswald mb-1">
        {label}
      </div>

      <div className="py-1 px-2 2xl:px-3 md:py-2 bg-ui-dark-gray rounded-xl flex flex-col items-center">
        <div className="relative w-full flex items-center justify-between gap-x-2">
          <div className="max-md:hidden text-[10px] w-11 flex items-center justify-center">
            {variation && !isFEG ? (
              <div className={`${hidePerc ? "hidden" : ""}`}>
                ({positiveVariation ? "+" : ""}
                {variation}%)
              </div>
            ) : isFEG ? (
              <div
                className={`h-3 w-3 rounded-full ${
                  fegToday <= 50 ? "bg-red-500" : "bg-green-500"
                }`}
              ></div>
            ) : null}
          </div>
          <div className="oswald font-bold text-sm md:text-lg">{value}</div>
          <div className="w-2 md:w-11 flex items-center justify-center">
            {!isFEG ? (
              variation && positiveVariation && !isFEG ? (
                <FaAngleDoubleUp className="text-blue-500" size={25} />
              ) : (
                <FaAngleDoubleDown className="text-orange-500" size={25} />
              )
            ) : (
              <div
                className={`h-3 w-3 rounded-full ${
                  fegYesterday <= 50 ? "bg-red-500" : "bg-green-500"
                }`}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
