import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { uniqBy } from "lodash";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import Loader from "../../Loader";
import { peRatioSorter } from "../../..//utils/dataCleaning";
import PeTable from "../PeTable";
import PeSingleElementCharts from "./PeSingleElementCharts";
import { AnimatePresence, motion } from "framer-motion";
import Accordion from "../../Accordion";
ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function generateChartData(labels, dataset) {
  return {
    labels,
    datasets: [
      {
        label: "P/E Ratio",
        data: dataset.map((e) => e.value),
        backgroundColor: "#D22326",
        borderSkipped: "bottom",
        borderRadius: 8,
        /*  parsing: {
          yAxisKey: 'peRatio'
        } */
      },
    ],
  };
}

function PEMainCharts() {
  const [dataset, setDataset] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [backendError, setBackendError] = useState(false);
  const [sortedDataset, setSortedDataset] = useState(null);
  const [labels, setLabels] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [enrichedSelected, setEnrichedSelected] = useState(null);

  useEffect(() => {
    setLoadingData(true);
    axios
      .get("https://api.bc.army/api/latest-pe-data/")
      .then(function (response) {
        // handle success
        if (response?.data?.peList) {
          const uniqList = uniqBy(response.data.peList, "id")
            .filter((e) => e.peRatio > 0)
            .map((el) => {
              return {
                ...el,
                ...el.revenues,
              };
            });
          /* const median = uniqList[Math.floor(uniqList.length / 2)].peRatio; */
          const filtered = uniqList.map((row) => {
            return {
              ...row,
              percToHolders: row.holdersRevenue / row.revenues.revenue1day,
              peDaily:Number(
                (row.marketCap / (row.revenues.revenue1day * 365)).toFixed(3)
              ),
              peWeekly: Number(
                (row.marketCap / (row.revenues.revenue1week * 52)).toFixed(3)
              )
            };
          });
          const sorted = peRatioSorter(filtered);
          setDataset(filtered);
          setSortedDataset(sorted);
          setLabels(sorted.map((e) => e.title));
        }
        setLoadingData(false);
      })
      .catch(function (error) {
        console.log(error);
        setBackendError(true);
        setLoadingData(false);
      });
  }, []);
  const handleDataSelection = (id) => {
    const allInfos = dataset.find((e) => e.id === id);
    setEnrichedSelected(allInfos ?? null);
    setSelectedData(id);
  };
  const handleGoBack = () => {
    setSelectedData(null);
    setEnrichedSelected(null);
  };

  const peChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label +=
              (context.raw * 100) / 100 >= 99
                ? "99+"
                : Math.round(context.raw * 100) / 100;
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        type: "logarithmic",
        grid: {
          color: "#383838",
        },
        ticks: {
          color: "#e0e0e0",
        },
      },
      x: {
        grid: {
          color: "transparent",
        },
        ticks: {
          color: "#e0e0e0",
        },
      },
    },
    onClick: function (evt, element) {
      if (element.length > 0) {
        handleDataSelection(sortedDataset[element[0].index].id);
      }
    },
    onHover: (event, chartElement) => {
      if (chartElement.length === 1) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },
  };

  return (
    <div className={` w-full flex flex-col flex-grow relative pt-10 md:pt-16`}>
      <Accordion
        title={"The P/E Dashboard"}
        content="The P/E (price-to-earnings) ratio in the crypto market represents the proportion of a token's market price to its revenue per token. A high P/E ratio may suggest that the token is overvalued or that holders expect significant growth. Cryptocurrencies without revenue lack a P/E ratio. The two primary P/E ratios in crypto are forward P/E, based on projected earnings, and trailing P/E, based on historical earnings. P/E ratios are most useful when comparing similar cryptocurrencies or analyzing the same cryptocurrency's performance over time. This dashboard provides insights into these P/E ratios, facilitating informed investment decisions."
      />
      {backendError && (
        <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full h-[90%] bg-ui-black rounded-md z-50 border border-neutral-300/30 flex flex-col items-center justify-center">
          <img
            src="/black-logo.webp"
            alt="bc logo"
            className="pointer-events-none h-[90%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10 "
          />
          <div className="text-2xl">We're victim of our own success. Too many people.</div><div className="text-3xl text-red-700"> We'll be back online soon.</div>
        </div>
      )}
      {loadingData ? (
        <div className="w-full flex items-center justify-center flex-grow">
          <div className="flex items-center flex-col">
            <Loader />
            <div className="text-white/70 text-sm">Loading Data...</div>
          </div>
        </div>
      ) : null}
      {dataset && labels && sortedDataset && !loadingData ? (
        <div
          className="w-full"
          style={{ display: selectedData ? "none" : "block" }}
        >
          <div className="w-full border border-neutral-300/20  rounded-sm p-5 h-[60vh] relative z-20">
            <img
              src="/black-logo.webp"
              alt="bc logo"
              className="pointer-events-none h-[70%] w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10 z-10"
            />

            <Bar
              options={peChartoptions}
              data={generateChartData(labels, sortedDataset)}
            />
          </div>
          <PeTable dataset={dataset} action={handleDataSelection} />
        </div>
      ) : null}
      <AnimatePresence>
        {selectedData && (
          <motion.div
            className="fixed top-16 md:absolute w-[calc(100%-2.5rem)] md:w-full bg-neutral-900"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                type: "spring",
                stiffness: 100,
                damping: 20,
                duration: 0.3,
              },
            }}
            exit={{ opacity: 0 }}
          >
            <PeSingleElementCharts
              goBack={() => handleGoBack()}
              id={selectedData}
              extraInfos={enrichedSelected}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
export default PEMainCharts;
