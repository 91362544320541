const PeTableRow = ({ infos, action }) => {
  const dailyPe = Number(
    (infos.marketCap / (infos.revenue1day * 365)).toFixed(3)
  );
  const weeklyPe = Number(
    (infos.marketCap / (infos.revenue1week * 52)).toFixed(3)
  );

  return (
    <>
      <div
        className="cursor-pointer w-full hidden md:grid grid-cols-3 md:grid-cols-8 border-b border-neutral-300/20  text-sm font-light "
        onClick={() => action(infos.id)}
      >
        <div className="w-full flex items-center flex-row gap-x-2 border-r border-neutral-300/20  px-2 py-1 overflow-hidden whitespace-nowrap">
          <img
            src={infos.logoUrl}
            alt="Logo"
            className="h-7 w-7 rounded-full"
          />
          <span className=" font-medium capitalize">{infos.name}</span>
        </div>
        <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.peDaily >= 99 ? "99+" : infos.peDaily.toLocaleString()}
        </div>
        <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.peWeekly >= 99 ? "99+" : infos.peWeekly.toLocaleString()}
        </div>
        <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.peRatio
            ? infos.peRatio >= 99
              ? "99+"
              : infos.peRatio.toLocaleString()
            : "0"}
        </div>
        <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.marketCap ? "$" + infos.marketCap.toLocaleString() : "$0"}
        </div>
        <div className="w-full hidden md:flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.revenue1day
            ? "$" + Math.floor(infos.revenue1day).toLocaleString()
            : "$0"}
        </div>
        <div className="w-full hidden md:flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.revenue1week
            ? "$" + Math.floor(infos.revenue1week).toLocaleString()
            : "$0"}
        </div>
        <div className="w-full hidden md:flex flex-row items-center  justify-end  border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.revenue1month
            ? "$" + Math.floor(infos.revenue1month).toLocaleString()
            : "$0"}
        </div>
        {/* <div className="w-full hidden md:flex flex-row border-r items-center  justify-end  border-neutral-300/20  px-2 py-1 overflow-hidden">
        {infos.percToHolders
          ? Math.floor(infos.percToHolders * 100).toLocaleString() + "%"
          : "0%"}
      </div> */}
        {/* 
      <div className="w-full flex flex-row border-r items-center  justify-end  border-neutral-300/20  px-2 py-1 overflow-hidden">
        {infos.revenue1year ? "$" + Math.floor(infos.revenue1year).toLocaleString() : "$0"}
      </div>
      <div className="w-full flex flex-row px-2 py-1 items-center  justify-end ">
        {infos.cumulativeRevenue ? "$" + infos.cumulativeRevenue.toLocaleString() : "$0"}
      </div> */}
      </div>
      <div
        className="cursor-pointer w-full grid md:hidden grid-cols-3 md:grid-cols-8 border-b border-neutral-300/20  text-sm font-light "
        onClick={() => action(infos.id)}
      >
        <div className="w-full flex items-center flex-row gap-x-2 border-r border-neutral-300/20  px-2 py-1 overflow-hidden whitespace-nowrap">
          <img
            src={infos.logoUrl}
            alt="Logo"
            className="h-7 w-7 rounded-full"
          />
          <span className=" font-medium capitalize">{infos.name}</span>
        </div>
        <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.peDaily >= 99 ? "99+" : infos.peDaily.toLocaleString()}
        </div>
        <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.peRatio
            ? infos.peRatio >= 99
              ? "99+"
              : infos.peRatio.toLocaleString()
            : "0"}
        </div>
        <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.marketCap ? "$" + infos.marketCap.toLocaleString() : "$0"}
        </div>
        <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
          {infos.peWeekly >= 99 ? "99+" : infos.peWeekly.toLocaleString()}
        </div>
      </div>
    </>
  );
};
export default PeTableRow;
