import axios from "axios";
import { useEffect, useState } from "react";
import { TokenKpis } from "../../homepage/TokenKPIS";
import Loader from "../../Loader";

export const TokenOverview = () => {
  const [loading, setLoading] = useState(true);
  const [tokenData, setTokenData] = useState(null);
  useEffect(() => {
    const savedArmyTokenData = localStorage.getItem("token-data");

    const fetchDataAndStore = async () => {
      try {
        const response = await axios
          .get("https://bcarmy-temp.vercel.app/api/get-army-token-data")
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        const dataToStore = response;
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 3);
        localStorage.setItem(
          "token-data",
          JSON.stringify({
            data: dataToStore,
            expiration: expirationTime.getTime(),
          })
        );
        setTokenData(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (!savedArmyTokenData) {
      setLoading(true);
      fetchDataAndStore();
    } else if (savedArmyTokenData && !JSON.parse(savedArmyTokenData).data) {
      localStorage.removeItem("token-data");
      setLoading(true);
      fetchDataAndStore();
    } else {
      const currentTime = new Date().getTime();
      const savedExpirationTime = JSON.parse(savedArmyTokenData).expiration;
      if (currentTime > savedExpirationTime) {
        localStorage.removeItem("token-data");
        setLoading(true);
        fetchDataAndStore();
      } else {
        setTokenData(JSON.parse(savedArmyTokenData).data);
        setLoading(false);
      }
    }
  }, []);
  return (
    <div className="w-full px-5 2xl:h-[calc((60vh-160px)/2)] 2xl:min-h-[225px]">
      <div className="oswald text-xl">$ARMY OVERVIEW</div>
      <div
        className="w-full  bg-neutral-300/20  mt-2.5 rounded-sm max-md:max-w-full bg-contain bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/black-logo.webp)`,
        }}
      >
        {!loading && tokenData ? (
          <a
            href="https://www.dextools.io/app/en/ether/pair-explorer/0xdea56d18ae37d64d75bb36a5469bb3753fe886d0?t=1731323612694"
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-full grid grid-cols-2 gap-y-10  2xl:gap-y-2.5 py-10 2xl:py-2.5">
              <TokenKpis
                value={"$" + tokenData.price.toFixed(4)}
                label={"Token Price"}
              />
              <TokenKpis
                value={"$" + tokenData.mktcap.toFixed(2)}
                label={"Token Market Cap"}
              />
              <TokenKpis value={tokenData.holders} label={"Holders"} />
              <TokenKpis
                value={"$" + tokenData.liquidity.toFixed(2)}
                label={"Token Liquidity Pool"}
              />
            </div>
          </a>
        ) : (
          <div className="w-full h-64 flex items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
