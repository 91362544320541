export const BurnCard = ({ classHover, img, path }) => {
  return (
    <a target="_blank" href={path} rel="noreferrer" className="max-md:w-full">
      <div
        className={`w-full md:w-[200px] aspect-square border border-neutral-300/30 hover:border-neutral-200 ${classHover} transition-all duration-300 rounded-md flex items-center justify-center overflow-hidden group`}
      >
        <img
          src={img}
          className="w-[80%] group-hover:scale-150 transition-all duration-300 "
          alt="burn logo"
        />
      </div>
    </a>
  );
};
