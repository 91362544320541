import PeTableHeaderElement from "./PeTableHeaderElement";

const PeTableHeader = ({ clickedSort, selectedId }) => {
  return (
    <>
      <div className="hidden md:grid w-full max-w-full overflow-auto  grid-cols-3 md:grid-cols-8 border-b border-neutral-300/20  text-sm pr-[6px] bg-neutral-900">
        <PeTableHeaderElement
          name="Name"
          id="name"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="PE (Daily)"
          id="peDaily"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="PE (Weekly)"
          id="peWeekly"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="PE (Monthly)"
          id="peRatio"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="Market Cap"
          id="marketCap"
          className={"flex max-md:col-start-1"}
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="Revenue (24h)"
          id="revenue1day"
          clickedSort={clickedSort}
          selectedId={selectedId}
          className={"hidden md:flex"}
        />
        <PeTableHeaderElement
          name="Revenue (7d)"
          id="revenue1week"
          clickedSort={clickedSort}
          selectedId={selectedId}
          className={"hidden md:flex"}
        />
        <PeTableHeaderElement
          name="Revenue (30d)"
          id="revenue1month"
          clickedSort={clickedSort}
          selectedId={selectedId}
          className={"hidden md:flex"}
        />
        {/*    <PeTableHeaderElement
        name="MC/FDV"
        id="mcfdv"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={'hidden md:flex'}
      /> */}
        {/* <PeTableHeaderElement
        name="% to holders"
        id="percToHolders"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={'hidden md:flex'}
      /> */}
      </div>
      <div className="grid md:hidden w-full max-w-full overflow-auto  grid-cols-3 md:grid-cols-8 border-b border-neutral-300/20  text-sm pr-[6px] bg-neutral-900">
        <PeTableHeaderElement
          name="Name"
          id="name"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="PE (Daily)"
          id="peDaily"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="PE (Monthly)"
          id="peRatio"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="Market Cap"
          id="marketCap"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
        <PeTableHeaderElement
          name="PE (Weekly)"
          id="peWeekly"
          clickedSort={clickedSort}
          selectedId={selectedId}
        />
      </div>
    </>
  );
};
export default PeTableHeader;
