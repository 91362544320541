import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "./App.css";
import HomePage from "./pages/Home";
import Sidebar from "./components/SideBar";
import Header from "./components/Header";
import MobileMenu from "./components/MobileMenu";
import PEAnalysis from "./pages/PEAnalysis";
import BurnPage from "./pages/Burn";
import BcLogo from "./components/BCLogo";
import BcLogoMobile from "./components/BCLogoMobile";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/pe-analysis",
    element: <PEAnalysis />,
  },
  {
    path: "/burn-dashboards",
    element: <BurnPage />,
  },
]);
function App() {
  return (
    <>
      {isMobile ? (
        <div className="w-[100dvw] h-[100dvh] p-10 bg-neutral-900">
          <div className="w-full h-full border border-neutral-300/70 rounded-md relative flex flex-col items-center justify-center">
            <BcLogoMobile />
            
            <div className="z-20 px-8 text-center text-white/90 mb-5">
              We are still working on the mobile version.
            </div>
            <div className="z-20 px-8 text-center text-white/90 font-semibold">
              Enjoy the experience from a desktop device.
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full md:h-screen md:flex md:flex-row">
          <Sidebar />
          <Header />
          <MobileMenu />
          <div className="flex flex-grow bg-neutral-900 overflow-y-auto">
            <RouterProvider router={router} />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
