import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../../Loader";
import TableHeader from "./data-table/TableHeader";
import TableRow from "./data-table/TableRow";
import { sortObjectsArray } from "../../../utils/dataCleaning";

export const DexesOveriew = () => {
  const [loading, setLoading] = useState(true);
  const [dexesData, setDexesData] = useState(null);
  const [sortBy, setSortBy] = useState("volumeLast24HoursUsd");
  const [isDescending, setIsDescending] = useState(true);
  const handleSorting = (val) => {
    if (sortBy === val) {
      setIsDescending(!isDescending);
    } else {
      setSortBy(val);
    }
  };
  useEffect(() => {
    const savedDexesData = localStorage.getItem("dexes-data");

    const fetchDataAndStore = async () => {
      try {
        const response = await axios
          .get("https://bcarmy-temp.vercel.app/api/get-dexes")
          .then(function (response) {
            const spreadRes = response.data.exchanges.edges.map((obj) => {
              return {
                ...obj.node,
                ...obj.node.metrics,
                ...obj.node.attributes,
              };
            });
            return spreadRes;
          })
          .catch(function (error) {
            console.log(error);
          });
        const dataToStore = response;
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 3);
        localStorage.setItem(
          "dexes-data",
          JSON.stringify({
            data: dataToStore,
            expiration: expirationTime.getTime(),
          })
        );
        setDexesData(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (!savedDexesData) {
      setLoading(true);
      fetchDataAndStore();
    } else if (savedDexesData && !JSON.parse(savedDexesData).data) {
      localStorage.removeItem("dexes-data");
      setLoading(true);
      fetchDataAndStore();
    } else {
      const currentTime = new Date().getTime();
      const savedExpirationTime = JSON.parse(savedDexesData).expiration;
      if (currentTime > savedExpirationTime) {
        localStorage.removeItem("dexes-data");
        setLoading(true);
        fetchDataAndStore();
      } else {
        setDexesData(JSON.parse(savedDexesData).data);
        setLoading(false);
      }
    }
  }, []);
  if (!dexesData)
    return (
      <div className="w-full  px-5 flex flex-col h-[225px] 2xl:h-[calc((60vh-160px)/2)] 2xl:min-h-[225px]">
        <div className="oswald text-xl ">DEXES OVERVIEW</div>
        <div className="flex items-center text-center justify-center w-full h-[calc(100%-50px)] border border-neutral-300/20 rounded-md text-xl">
          Data is on the way. <br />
          Brace for impact.
        </div>
      </div>
    );
  return (
    <div className="w-full h-[calc(100vh-160px)] 2xl:h-[calc(60vh-160px)] 2xl:min-h-[450px] px-5 flex flex-col">
      <div className="oswald text-xl ">DEXES OVERVIEW</div>
      <div className="oswald text-sm 2xl:text-xs ">
        Protocol volumes in the last 24h
      </div>
      <div className="flex flex-col h-[calc(100%-50px)]">
        {loading && !dexesData ? (
          <div className="h-full w-full flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div className="h-full">
            <div
              className="flex flex-col w-full h-full border border-neutral-300/20  mt-2.5 mb-[40px] rounded-sm max-md:max-w-full max-md:overflow-x-auto bg-contain bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/black-logo.webp)`,
              }}
            >
              <TableHeader clickedSort={handleSorting} selectedId={sortBy} />
              <div className="w-full overflow-y-auto flex flex-col grow">
                {dexesData?.length &&
                  sortObjectsArray(dexesData, sortBy, isDescending).map(
                    (el) => <TableRow infos={el} key={el.id} />
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
