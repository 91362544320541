import { useEffect, useState } from "react";
import Loader from "../../Loader";
import axios from "axios";
import InflowsChart from "./InflowsChart";
import { BcSign } from "../../Sign";

export const Inflows = () => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [btcPrice, setBtcPrice] = useState(0);
  useEffect(() => {
    const getBtcPrice = async () => {
      try {
        const response = await axios
          .get("https://blockchain.info/ticker")
          .then(function (response) {
            setBtcPrice(response.data.USD.last);
            return response.data.USD.last;
          })
          .catch(function (error) {
            console.log(error);
          });

        return response;
      } catch (error) {
        console.log(error);
      }
    };
    getBtcPrice();
    const savedInflowData = localStorage.getItem("inflows-data");

    const fetchDataAndStore = async () => {
      try {
        const response = await axios
          .get("https://bcarmy-temp.vercel.app/api/get-inflow-data")
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        const dataToStore = response;
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 3);
        localStorage.setItem(
          "inflows-data",
          JSON.stringify({
            data: dataToStore,
            expiration: expirationTime.getTime(),
          })
        );
        setChartData(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (!savedInflowData) {
      setLoading(true);
      fetchDataAndStore();
    } else if (savedInflowData && !JSON.parse(savedInflowData).data) {
      localStorage.removeItem("inflows-data");
      setLoading(true);
      fetchDataAndStore();
    } else {
      const currentTime = new Date().getTime();
      const savedExpirationTime = JSON.parse(savedInflowData).expiration;
      if (currentTime > savedExpirationTime) {
        localStorage.removeItem("inflows-data");
        setLoading(true);
        fetchDataAndStore();
      } else {
        setChartData(JSON.parse(savedInflowData).data);
        setLoading(false);
      }
    }
  }, []);
  return (
    <div className="w-full h-[60vh] md:h-[calc(100vh-160px)] px-5 2xl:h-[calc(60vh-160px)] 2xl:min-h-[450px]">
      <div className="oswald text-xl">ETF's OVERVIEW</div>
      <div className="oswald text-sm 2xl:text-xs">
        ETFs inflows and outflows over time
      </div>
      {loading && !chartData ? (
        <div className="w-full h-[calc(100%-40px)] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="w-full h-[calc(100%-40px)] relative">
          <img
            src="/black-logo.webp"
            alt="bc logo"
            className="pointer-events-none h-[50%] md:h-[90%] md:w-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invert opacity-10"
          />
          <BcSign />
          {chartData && chartData.chart4 ? (
            <InflowsChart data={chartData} btcPrice={btcPrice} />
          ) : (
            <div>Data - error</div>
          )}
        </div>
      )}
    </div>
  );
};
