import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useRef } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


function generateChartData(labels, dataset) {
  const datasets = [{
    label: "Last 7D Percent",
    data: dataset.map((e) => e.last_7d_percent),
    backgroundColor: "#D22326",
    borderSkipped: "bottom",
    borderRadius: 4,
    order: 0,

    /*  parsing: {
      yAxisKey: 'peRatio'
    } */
  }]
  datasets.push({
    label: "Avg Last 7D Percent",
    data: dataset.map((e) => e["avg_last_7d_percent_across_all_narratives"]),
    backgroundColor: "#75ff6b",
    borderSkipped: "bottom",
    borderColor: "#75ff6b",
    type: "line",
    order: 2,
    borderWidth: 2,
    pointStyle: false,
  });
  return {
    labels,
    datasets
  };
}
const DuneChart = ({ data }) => {
  const chartRef = useRef(null); // Added ref
  const labels = data.map((el) => el.narrative);
  const peChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            console.log(context)
            /* if (context.dataset.label === "Price") {
              return "Price: $" + Number(context.raw).toFixed(2);
            } else {
              return (
                context.dataset.label + ": ₿ " + Number(context.raw).toFixed()
              );
            } */
          },
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        grid: {
          color: "#e0e0e0",
        },
        ticks: {
          color: "#e0e0e0",
          callback: function (value, index, ticks) {
            return  (Math.round(value * 100)).toFixed()+'%';
          },
        },
      },
      x: {
        stacked: true,
        ticks: {
          color: "#e0e0e0",
        },
      },
    },
  };

 
 /*   useEffect(()=>{
    if(chartRef) {
      chartRef.current.data.datasets.forEach((dataset) => {
        if (dataset.label !== "TOTAL" && dataset.label !== "Price") {
          dataset.hidden = !dataset.hidden;
        }
      });
      chartRef.current.update();
    }
  }, []) */
  return (
    <div className="w-full h-full flex flex-col items-center">
      <Bar
        options={peChartoptions}
        data={generateChartData(labels, data)}
        ref={chartRef}
      />
      {/* Connected ref */}
    </div>
  );
};

export default DuneChart;
